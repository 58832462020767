import React, {useState,useRef, Dispatch, forwardRef, SetStateAction, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, RadioButton, TextField, useCopyToClipboard, useNotification } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import { addCamera, getDeviceInfo, updateCamera } from 'services/apiConfig';
import { useHistory } from 'react-router-dom';
import { streamKeyName } from 'utils/validation';
import AuthService from 'services/authService';
import { RTMP_IP_URL, RTMP_MDNS_URL, RTMP_EXTERNAL_EXAMPLE_URL } from '../../constants';

const Container = styled.div`
  margin: 0;
  padding-top: 28px;
  user-select: none;
`;

const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 30px;
  max-width: 840px;
`;

const ServerBox = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: -15px;
`;

const RadioWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

const StatusLabel = styled(Label)`
  font-size: 12px;
  line-height: 1.6;
`;

const SourceLabel = styled(Label)`
  font-size: 14px;
  line-height: 1.6;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const InputFieldBox = styled.div`
  max-width: 620px;
  margin-top: 17px;
`;

const CopyIcon = styled.div<{ja:boolean}>`
  position: absolute;
  top: 40px;
  right: 13px;
  cursor: pointer;
  width: 20px;
  height: 21px;

  &:active, &:focus {
    outline: none;
    &:after {
      ${({ ja }) => ja ? css` content :'コピー品です';` : css` content : 'Copied!';`}
      background: rgba(0,0,0,.72);
      color: #fff;
      font-size: 12px;
      padding: 5px;
      white-space: nowrap;
      top: 24px;
      left: 28px;
      position: absolute;
    }
  }
`;

const IPDNSbutton = styled.div`
  position: absolute;
  display:flex;
  right: 0;
  button{
    margin-left: 10px;
  }
`;

const TextFieldRequired = styled.div<{ required?: boolean }>`
  ${({ required }) => required && css`
      span::after{
      font-weight: bold;
      content: " *";
      color: rgb(238, 75, 43);
    }
  `}
`;

interface IBasicConfig {
  camera_name: string;
  camera_type: string;
  camera_enabled: boolean;
  notes: string;
}

interface IRTMPCameraData {
  rtmp_server_type: string;
  rtmp_source_url: string;
  rtmp_stream_key: string;
  rtmp_url_type: boolean;
}

interface IRTMPCamera {
  basicConfig: IBasicConfig;
  rtmpCamera: IRTMPCameraData;
  isEditCamera: boolean;
  streamName: string;
  setRtmpCamera: Dispatch<SetStateAction<IRTMPCameraData>>;
  setEditButtonLoading:(data:boolean)=>void;
  serverUrlTypeRTMP:string;
  setCompareNewValues: (data:any)=>void;
}

interface IInterfaces {
  interface: string,
  ip_address: string
}

const RTMPCamera = forwardRef<any, IRTMPCamera>(({ basicConfig, rtmpCamera, setRtmpCamera, isEditCamera, streamName, setEditButtonLoading, serverUrlTypeRTMP, setCompareNewValues }, ref) => {

  const [deviceInfo, setDeviceInfo] = useState({'device_id': '', 'device_ip': ''});
  const { t } = useTranslation(['CommonDict']);
  const { copyToClipboard } = useCopyToClipboard();
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { push } = useHistory();
  const [getVideoConfigurationData, setGetVideoConfigurationData] = useState({serverUrl:'', streamName:'', serverType:''});
  const rtmpCameraRef = useRef(rtmpCamera);

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      try {
        const { data: { data, status_code } } = await getDeviceInfo();
        if (status_code === '10000') {
          let deviceIP = '';
          const interfaces = data.network.interfaces;
          Object.entries(interfaces).map((arr)=>{
            const infData = (arr[1] as IInterfaces);
            if(infData.interface === 'eno1.inet'){
              deviceIP = infData.ip_address.split('/')[0];
            }
            if(deviceIP === '' && infData.interface === 'eth0.inet'){
              deviceIP = infData.ip_address.split('/')[0];
            }
            if(deviceIP === '' && infData.interface === 'wlan0.inet'){
              deviceIP = infData.ip_address.split('/')[0];
            }
            return arr;
          });
          setDeviceInfo({'device_id': data?.device_id, 'device_ip': deviceIP});
        } else {
          notificationRef.current({ type: 'success', message: t('Failed to load device info') });
        }
      } catch (error) {
        console.error((error as Error).message);
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    };
    fetchDeviceInfo();
  }, [t]);

  const randomWord = useMemo(() => 'RTMP' + Math.random().toString(36).substring(2, 4 + 2), []);
  
  useEffect(() => {
    if (!isEditCamera) {
      if (rtmpCamera.rtmp_server_type === 'Internal') {
        setRtmpCamera(prev => ({ ...prev, rtmp_stream_key: randomWord }));
      } else {
        setRtmpCamera(prev => ({ ...prev, rtmp_stream_key: '' }));
      }
    }
  }, [isEditCamera, rtmpCamera.rtmp_server_type, setRtmpCamera, randomWord]);

  useImperativeHandle(ref, () => ({
    postData: async() => {
      const data = { ...basicConfig,  camera_name: basicConfig.camera_name.trim(), notes: basicConfig.notes.trim(), 'video_configuration': { ...rtmpCamera } };
      if (rtmpCamera?.rtmp_source_url==='') {
        notificationRef.current({
          type: 'error', message: t('Please enter server URL.')
        });
        return;
      }
      const reg = new RegExp(/^(rtmp):\/\/[^ "]+$/);
      if (!(reg.test(rtmpCamera?.rtmp_source_url))) {
        notificationRef.current({
          type: 'error', message: t('Invalid server URL.')
        });
        return;
      }
      try {
        if(isEditCamera){
          setEditButtonLoading(true);
          const { data: { status_code } } = await updateCamera({ ...data, stream_name: streamName });
          if (status_code === 201) {
            notificationRef.current({ type: 'success', message: t('Camera details updated successfully') });
            push('/cameras');
          }
          else if (status_code === 403) {
            notificationRef.current({ type: 'error', message: t('Authorization required to access') });
            if (await AuthService.logoutUser() !== 200) {
              notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
            }
          }
          else {
            notificationRef.current({ type: 'error', message: t('Failed to add camera') });
          }
        }else{
          setEditButtonLoading(true);
          const { data: { status_code } } = await addCamera(data);
          if (status_code === 201) {
            notificationRef.current({ type: 'success', message: t('Camera added successfully') });
            setEditButtonLoading(false);
            push('/cameras');
          }
          else if (status_code === 403) {
            notificationRef.current({ type: 'error', message: t('Authorization required to access') });
            if (await AuthService.logoutUser() !== 200) {
              notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
            }
          }
          else {
            notificationRef.current({ type: 'error', message: t('Failed to add camera') });
          }
        }
        setEditButtonLoading(false);
      } catch (error) {
        console.error((error as Error).message);
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
      setEditButtonLoading(true);
    }
  }));

  useEffect(()=>{
    const data = { ...basicConfig,  camera_name: basicConfig.camera_name.trim(), notes: basicConfig.notes.trim(), 'video_configuration': { ...rtmpCamera } };
    setCompareNewValues(data);
  }, [basicConfig, rtmpCamera, setCompareNewValues]);

  useEffect(() => {
    if(isEditCamera){
      if (rtmpCamera.rtmp_server_type === 'External' && getVideoConfigurationData.streamName === '') {
        setGetVideoConfigurationData({serverUrl:rtmpCameraRef.current.rtmp_source_url, streamName:rtmpCameraRef.current.rtmp_stream_key, serverType: rtmpCamera.rtmp_server_type });
      } else if(rtmpCamera.rtmp_server_type === 'Internal' && getVideoConfigurationData.streamName === '') {
        setGetVideoConfigurationData({serverUrl:'', streamName:rtmpCameraRef.current.rtmp_stream_key, serverType: rtmpCamera.rtmp_server_type});
      }
    }
    if (rtmpCamera.rtmp_server_type === 'Internal') {
      setRtmpCamera(prev => ({ ...prev, rtmp_source_url: rtmpCamera.rtmp_url_type ? RTMP_IP_URL.replace('{X}', deviceInfo.device_ip) : RTMP_MDNS_URL.replace('{X}', deviceInfo.device_id)}));
      if(isEditCamera){
        if(getVideoConfigurationData.streamName !== '' && rtmpCamera.rtmp_server_type === 'Internal'){
          setRtmpCamera(prev => ({ ...prev, rtmp_stream_key: getVideoConfigurationData.streamName }));
        }
        if(getVideoConfigurationData.serverType === 'External' && rtmpCamera.rtmp_server_type === 'Internal'){
          setRtmpCamera(prev => ({ ...prev, rtmp_stream_key: randomWord }));
        }
      }
    } else {
      if(isEditCamera){
        if(getVideoConfigurationData.serverUrl !== '' && rtmpCamera.rtmp_server_type === 'External'){
          setRtmpCamera(prev => ({ ...prev, rtmp_source_url: getVideoConfigurationData.serverUrl, rtmp_stream_key: getVideoConfigurationData.streamName }));
        }
        if(getVideoConfigurationData.serverType === 'Internal' && rtmpCamera.rtmp_server_type === 'External'){
          setRtmpCamera(prev => ({ ...prev, rtmp_stream_key: '' , rtmp_source_url: ''}));
        }
      }
    }
  }, [deviceInfo, isEditCamera, rtmpCamera.rtmp_server_type, rtmpCamera.rtmp_url_type, setRtmpCamera, randomWord, getVideoConfigurationData.serverType, getVideoConfigurationData.serverUrl, getVideoConfigurationData.streamName ]);

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    if (name === 'rtmp_stream_key') {
      if (streamKeyName(value) || value === '') {
        setRtmpCamera((prev) => ({ ...prev, rtmp_stream_key: value }));
      }
    } else {
      setRtmpCamera((prev) => ({ ...prev, [name]: value }));
    }
  }, [setRtmpCamera]);

  const onChangeSelector = useCallback((event, name) => {
    setRtmpCamera((prev) => ({ ...prev, [name]: event as string }));
    if(!isEditCamera){
      setRtmpCamera(prev => ({ ...prev, rtmp_source_url: ''}));
    }
  }, [setRtmpCamera, isEditCamera]);

  const onClickIPDNS = useCallback(() => {
    setRtmpCamera((prev) => ({ ...prev, rtmp_url_type: !prev.rtmp_url_type }));
  }, [setRtmpCamera]);

  useEffect(()=>{
    if(isEditCamera){
      if(serverUrlTypeRTMP === '0'){
        setRtmpCamera((prev) => ({ ...prev, rtmp_url_type: false}));
      }
    }
  }, [isEditCamera, serverUrlTypeRTMP, setRtmpCamera]);

  return (
    <Container>
      <InputFieldContainer>
        <ServerBox>
          <Wrapper>
            <Label htmlFor='server_internal' labelText=''>
              <RadioWrapper>
                <FlexBox>
                  <StatusLabel htmlFor='server_internal' labelText={t('Internal Server')} rightAlign />
                  <SourceLabel htmlFor='server_internal' labelText={t('Receive Source')} rightAlign />
                </FlexBox>
                <RadioButton
                  tabIndex={4}
                  id='server_internal'
                  value='Internal'
                  onChangeCallback={(event: string | number) => onChangeSelector(event, 'rtmp_server_type')}
                  currentChecked={rtmpCamera.rtmp_server_type}
                  disabled={false}
                />
              </RadioWrapper>
            </Label>
            <Label htmlFor='server_external' labelText=''>
              <RadioWrapper>
                <FlexBox>
                  <StatusLabel htmlFor='server_external' labelText={t('External Server')} rightAlign />
                  <SourceLabel htmlFor='server_external' labelText={t('Fetch Source')} rightAlign />
                </FlexBox>
                <RadioButton
                  tabIndex={5}
                  id='server_external'
                  value='External'
                  onChangeCallback={(event: string | number) => onChangeSelector(event, 'rtmp_server_type')}
                  currentChecked={rtmpCamera.rtmp_server_type}
                  disabled={false}
                />
              </RadioWrapper>
            </Label>
          </Wrapper>
        </ServerBox>
        <InputFieldBox>
          <ServerBox>
            {rtmpCamera.rtmp_server_type === 'Internal' &&
              <IPDNSbutton>
                <Button onClick={onClickIPDNS} design={rtmpCamera.rtmp_url_type ? 'primary' : 'secondary'} size='xsmall'>{t('IP')}</Button>
                <Button onClick={onClickIPDNS} design={rtmpCamera.rtmp_url_type ? 'secondary' : 'primary'} size='xsmall'>{t('mDNS')}</Button>
              </IPDNSbutton>}
            <TextFieldRequired required={rtmpCamera.rtmp_server_type === 'External'}>
              <TextField
                tabIndex={6}
                name='rtmp_source_url'
                label={t('Server URL')}
                fieldState={rtmpCamera.rtmp_server_type === 'External'? 'default' : 'disabled'}
                onChange={onChangeHandler}
                value={rtmpCamera.rtmp_source_url}
                maxLength={255}
                placeholder={RTMP_EXTERNAL_EXAMPLE_URL}
              />
            </TextFieldRequired>
            {rtmpCamera.rtmp_server_type === 'Internal' &&
              <CopyIcon ja={i18n.language === 'ja'} title={t('Copy')} onClick={() => { copyToClipboard(rtmpCamera.rtmp_source_url);}}>
                <Icon size={20} icon='Copy' color='dimmed' />
              </CopyIcon>}
          </ServerBox>
          <TextField
            tabIndex={7}
            name='rtmp_stream_key'
            label={t('Stream Name / Key')}
            placeholder={t('Enter Stream Name / Key …')}
            fieldState='default'
            onChange={onChangeHandler}
            value={rtmpCamera.rtmp_stream_key}
            maxLength={255}
          />
        </InputFieldBox>
      </InputFieldContainer>
    </Container>
  );
});

export default RTMPCamera;
