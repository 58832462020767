import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';

const DeleteConfirmationText = styled.div`
  margin-bottom: 26px;
`;

const ActionContainer = styled.div`
  margin: 45px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 0 10px;
`;

const ButtonContainer = styled.div`
  margin-top:-4px;
  display: flex;
  gap: 0 8px;
  justify-content: flex-end;

  & > #NoButton:focus {
    outline: 2px solid #838383;
  }

  & > #YesButton:focus {
    outline: 2px solid #838383;
  }
`;

const IUnderstandDiv = styled.div`
  cursor: pointer;
  margin-top:-3px;
`;

const ResetHeader = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin: 12px 0 28px;
  color: #727980;
`;

const ModalContainer = styled.div`
  max-width: 580px;
  width: 100%;
  height: 322px;
  padding: 20px 40px;
`;

interface IProps  {
  onReset: () => void;
}

const SafieConfirmModal: React.FC<IProps> = ({onReset}) => {
   
  const [iUnderstand, setIUnderstand] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const { isModalOpen, setModalOpen } = useModal();

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();
    
      if (e.key === 'ArrowLeft') {
        document.getElementById('NoButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('NoButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'NoButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('NoButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('NoButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);
  
  const onCheckboxChange = useCallback(()=>{    
    setIUnderstand(prev => !prev);
    document.getElementById('NoButton')?.focus();
  }, []);

  return (
    <ModalContainer>
      <ResetHeader>{t('Reset Safie credentials')}</ResetHeader>
      <DeleteConfirmationText>
        {t('This will clear your Safie credentials from this system and require you to setup a new OTP.')}
      </DeleteConfirmationText>
      <DeleteConfirmationText>
        {`${t('WARNING')}: ${t('This reset will cause any Safie Camera\'s currently being used to cease functioning.')}`}
      </DeleteConfirmationText>
      <ActionContainer>
        <CheckboxContainer>
          <Checkbox checked={iUnderstand} onChangeCallback={onCheckboxChange} />
          <IUnderstandDiv onClick={onCheckboxChange}>{t('I understand')}</IUnderstandDiv>
        </CheckboxContainer>
        <ButtonContainer>
          <Button
            id='NoButton'
            design='secondary'
            onClick={() => setModalOpen(false)}
          >
            {t('Back')}
          </Button>
          <Button
            id='YesButton'
            design='danger'
            disabled={!iUnderstand}
            onClick={() => onReset()}
          >
            {t('Reset')}
          </Button>
        </ButtonContainer>
      </ActionContainer>
    </ModalContainer>
  );
};

export default SafieConfirmModal;