import api from './api';
import TokenService from './tokenService';
import { IUserResponse } from 'interface';
// import axios from 'axios';

const loginUser = async (username: string, password: string) => {
  const res = await  api.userInstance.post<IUserResponse>('/users/login', { username, password});
  if (res.data.data && res.data.data.access_token) {
    TokenService.setUser(res.data.data);
  }
  return res.data;
};

const logoutUser = async () => {
  const res = await api.userInstance.post<any>(`/users/${TokenService.getUserID()}`, { action: 'logout', session_id: TokenService.getUserSessionId() });

  if (res.status === 200) {
    TokenService.removeUser();
    window.location.href = '/login';
    return res.data.status;
  }
  return res.data.status;
};

const AuthService = {
  loginUser,
  logoutUser
};

export default AuthService;