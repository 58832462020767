import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButtons, Button, ButtonWithIcon, Content, Label, MultilineContent, PageHeader, Tab, TabContent, TabList, Tabs, TypeTable, useModal, useNotification } from 'scorer-ui-kit';
import { ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import { DATE_FORMATT_WITH_HYPHEN } from '../../constants';
import { getAllUsers, getGuestUser, putGuestUpdate } from 'services/apiConfig';
import ModelBox from 'components/ModelBox';
import AuthService from 'services/authService';
import { capitalizeWords } from 'utils';

const Container = styled(Content)`
  max-width: 1121px;
  min-height: 90vh;
  overflow: hidden;
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  max-width: 954px;
  line-height: 1.2;
  > div:first-child > p {
    font-weight: normal;
    font-style: normal;
  }
`;

const NotesBox = styled.div`
  max-width: 18vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const UsernameBox = styled.div`
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TableBox = styled.div`
  position: relative;
  z-index: 0;
  max-width: 920px;
`;

const EditButton = styled.div`
  margin-right: 7px;
`;

const GuestContainer = styled.div`
  display: flex;
  position: relative;
  top: 51px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 944px;
`;

const GuestBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const GuestTextBox = styled.div`
  max-width: 539px;
`;

const GuestTitle = styled(Label)`
  font-size: 20px;
  color: #5a6269;
  margin-bottom: 0;
`;

const GuestButton = styled(Button)`
  margin-bottom: 8px;
`;

const GuestTitleSubtitle = styled(Label)`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.79;
  margin-bottom: 0;
`;

const ModelText = styled(Label)`
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const TabLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
  margin-top: -1.1px;
`;

const JSTFormat = styled.div`
  font-size: 12px;
  font-style: italic;
  text-align: right;
  color: rgba(120, 138, 144, 0.72);
  margin-left: 3px;
`;

const DateFormating =styled.div`
  display:flex;
  flex-direction:row;
`;

const StatusTextEnabled = styled(Label)`
  span {
    margin-bottom: -1px;  
  }
  font-size: 16px;
  color: #5a6269;
  margin: 0 0 8px 4px;
  color: rgb(92, 200, 121);
`;

const StatusTextDisabled = styled(Label)`
  span {
    margin-bottom: -1px;  
  }
  font-size: 16px;
  color: #5a6269;
  margin: 0 0 8px 4px;
  color: hsla(0,0%,55.3%,1.000);
`;

const AdvanceTextBox = styled.div`
  width: 518px;
`;

const Formatter = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  
`;

interface IDevice {
  created: string
  last_login: string
  note: string
  role: string
  user_id: number
  username: string
}

const UserManagement: FC = () => {
  const [userData, setUserData] = useState([]);
  const [rows, setRows] = useState<ITypeTableData>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const { push } = useHistory();
  const [guestStatus, setGuestUser] = useState(false);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { createModal, setModalOpen } = useModal();
  const [ selectedTab, setSelectedTab ] = useState<string>('users');
  const params = useLocation().search;
  const [ historyParams ] = useState<string>(params);
  const { replace } = useHistory();

  const fetchHistoryParams = useCallback(() => {
    const urlString = new URLSearchParams(historyParams);
    const tabName = urlString.get('tab');
    if(tabName){
      setSelectedTab(tabName);
    } else {
      setSelectedTab('users');
      replace(window.location.pathname + '?tab=users');
    }
  }, [ historyParams, setSelectedTab, replace ]);

  useEffect(() => {
    fetchHistoryParams();
  }, [ fetchHistoryParams ]);

  const fetchAllUserList = useCallback(async () => {
    setLoading(true);
    try {
      const { data: { status, data } } = await getAllUsers();
      if (status === 200) {
        setUserData(data);
      } else if (status === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load user data') });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAllUserList();
  }, [fetchAllUserList]);

  const columnConfig: ITableColumnConfig[] = useMemo(() => [
    {
      header: t('Username'),
      cellStyle: 'firstColumn',
      minWidth: 150,
    },
    {
      header: t('Type'),
      cellStyle: 'normalImportance',
      minWidth: 100,
    },
    {
      header: t('Created'),
      cellStyle: 'normalImportance',
      alignment: 'left',
      minWidth: 170,
    },
    {
      header: t('Last login'),
      cellStyle: 'normalImportance',
      alignment: 'left',
      minWidth: 170,
    },
    {
      header: t('Notes'),
      cellStyle: 'normalImportance',
      showUnit: true,
      alignment: 'left',
    },
    {
      header: t('Edit'),
      cellStyle: 'normalImportance',
      alignment: 'right',
      minWidth: 80
    },
  ], [t]);

  const generateCreatedColumn = useCallback((data: string | null): ReactElement[] => {
    if (data) {
      return [<DateFormating key={data}><div key=''>{`${format(new Date(data), DATE_FORMATT_WITH_HYPHEN)}`}</div> <JSTFormat>JST</JSTFormat></DateFormating>];
    } else {
      return [<div key=''>&emsp; &nbsp; -</div>];
    }
  }, []);

  const generateUserNameColumn = useCallback((data = '-'): ReactElement[] => {
    if (data.trim() === '') {
      return [<UsernameBox key=''>-</UsernameBox>];
    }
    return [<UsernameBox key='' title={data}>{data}</UsernameBox>];
  }, []);

  const generateNotesColumn = useCallback((data = '-'): ReactElement[] => {
    if (data.trim() === '') {
      return [<NotesBox key=''>&emsp; &nbsp; -</NotesBox>];
    }
    return [<NotesBox key='' title={data}>{data}</NotesBox>];
  }, []);

  const generateRowData = useCallback(() => {
    const data: ITypeTableData = userData.map((device: IDevice) => {
      const { user_id, username, created, role, note, last_login } = device;
      const row = {
        columns: [
          {
            customComponent: (
              <MultilineContent
                contentArray={generateUserNameColumn(username)}
              />
            ),
          },
          { text: ` ${t(capitalizeWords(role === 'admin' ? 'administrator' : role ))}` },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateCreatedColumn(created)}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateCreatedColumn(last_login)}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateNotesColumn(note)}
              />
            ),
          },
          { customComponent: <EditButton><ActionButtons alignment='right' buttonsConfig={[{ icon: 'Edit', size:18, onClick: () => { push(`/settings/edit-user?user-id=${user_id}`); } }]} /></EditButton> },
        ],
        id: user_id,
      };
      return row;
    });
    return data;
  }, [userData, push, generateUserNameColumn, generateCreatedColumn, generateNotesColumn, t]);

  useEffect(() => {
    setRows(generateRowData());
  }, [userData, generateRowData]);


  const fetchGuestData = useCallback(async () => {
    try {
      const { data: { data, status_code } } = await getGuestUser();
      if (status_code === 200) {
        setGuestUser(data.guest_enabled);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        setGuestUser(false);
      }
    } catch (error) {
      setGuestUser(false);
      console.error((error as Error).message);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
  }, [t]);

  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);

  const onGuestUser = useCallback(async () => {
    try {
      const { data: { status_code } } = await putGuestUpdate(!guestStatus);
      if (status_code === 200) {
        setGuestUser(!guestStatus);
        sendNotification({ type: 'success', message: t(`Guest Access ${guestStatus ? 'disabled' : 'enabled'} successfully.`) });
        setModalOpen(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        sendNotification({ type: 'error', message: t('Failed to load guest status') });
      }
    } catch (error) {
      console.error((error as Error).message);
      sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
    }
  }, [setModalOpen, guestStatus, t, sendNotification]);

  const onClickGuestUser = useCallback(() => {
    createModal({
      isCloseEnable: false,
      width: '480px',
      padding: true,
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (
        <ModelBox onConfirm={() => { onGuestUser(); }} modalOpen={setModalOpen} cancelText='No' confirmText='Yes' confirmStyle='primary'>
          <ModelText htmlFor='' labelText={t(`Are you sure you want to ${guestStatus ? 'disable': 'enable'} guest user?`)} />
        </ModelBox>),
    });
  }, [guestStatus, onGuestUser, createModal, setModalOpen, t]);

  const onClickTab = useCallback((tabName:string) => {
    setSelectedTab(tabName);
    replace(window.location.pathname + '?tab=' + tabName);
  },[replace]);

  return (
    <Container>
      <HeaderBox>
        <PageHeader
          areaTitle={t('Settings')}
          icon='ViewSettings'
          introductionText={t('Manage the users that have access to this instance of the Traffic Counter system .')}
          title={t('User Management')}
          updateDocTitle={false}
        />
        <ButtonWithIcon
          design='primary'
          icon='Add'
          position='left'
          size='small'
          onClick={() => push('/settings/create-user')}
        >
          {t('Create New User')}
        </ButtonWithIcon>
      </HeaderBox>
      <Tabs>
        <TabList defaultTabId={selectedTab}>
          <Tab tabFor='users' onClick={()=>onClickTab('users')}>{t('Users')}</Tab>
          <Tab tabFor='guest' onClick={()=>onClickTab('guest')}>{t('Guest Access')}</Tab>
        </TabList>
        <TabLine />
        <TabContent tabId='users'>
          <TableBox>
            <TypeTable
              columnConfig={columnConfig}
              rows={rows.length > 0 ? rows : [{ columns: [] }]}
              isLoading={loading}
              loadingText={t('Loading')}
              emptyTableText={t('No Users Found')}
              defaultAscending
              hasHeaderGroups
            />
          </TableBox>
        </TabContent>
        <TabContent tabId='guest'>
          <GuestContainer>
            <AdvanceTextBox>
              <Formatter>
                <GuestTitle htmlFor='' labelText={t('Enable Guest Access{Heading}').replace('{Heading}', '')} />
                {guestStatus ?
                  <StatusTextEnabled htmlFor='' labelText={t('Enabled')} />:
                  <StatusTextDisabled htmlFor='' labelText={t('Disabled')} />}
              </Formatter> 
            </AdvanceTextBox> 
            <GuestBox>
              <GuestTextBox>
                <GuestTitleSubtitle htmlFor='' labelText={t('This will give anyone the read-only access to this service without needing a user name or password.')} />
              </GuestTextBox>
              <GuestButton design={guestStatus ? 'secondary' : 'primary'} onClick={onClickGuestUser}>{guestStatus ? t('Disable Guest Access') : t('Enable Guest Access')}</GuestButton>
            </GuestBox>
          </GuestContainer>
        </TabContent>
      </Tabs>
    </Container>
  );
};

export default UserManagement;