import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, useModal } from 'scorer-ui-kit';
import UpdatePassword from 'pages/UpdatePassword';
import styled from 'styled-components';
import TokenService from 'services/tokenService';
import AuthService from 'services/authService';


const AccountOptionBox = styled.div`
  padding: 20px 20px 15px;
  border-bottom: hsla(0,0%,93.3%,1.000) 1px solid;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  color: hsla(0,0%,34.1%,0.749);
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

const AccountOption = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: hsla(0,0%,34.5%,0.902);
  margin-bottom: 0;
  margin: -10px 0 0 0;
`;

const PasswordReset = styled(Label)`
  color: rgba(88, 88, 88, 0.65);
  font-weight: 300;
  cursor: pointer;
`;

const PasswordResetGuest = styled(Label)`
  color: rgba(88, 88, 88, 0.65);
  font-weight: 300;
  cursor: pointer;
  margin-top: 34px;
`;

const PasswordText = styled(Label)`
  margin: 18px 0 -10px 0px;
  color: rgba(88, 88, 88, 0.65);
  font-weight: 300;
  cursor: pointer;
`;




const LogOut = styled.div`
  padding: 20px 20px 15px;
`;

const Border = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #eee;
`;

const BorderGuest = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #eee;
  margin-top: -70px;
`;

interface IProps {
  boundingBoxes: any;
  setBoundingBoxes: any;
}

const PasswordModal: FC<IProps> = () => {

  const { createModal, setModalOpen } = useModal();
  const { t } = useTranslation(['CommonDict']);



  const updateModel = useCallback(() => {
    createModal({
      isCloseEnable: true,
      width: '460px',
      padding: true,
      closeText: t('CLOSE'),
      dismissCallback: () => { setModalOpen(false); },
      customComponent: <UpdatePassword />,
    });
  }, [createModal, setModalOpen, t]);

  const guestLogout = useCallback(() => {
    sessionStorage.setItem('isGuestLogout', 'true');
    TokenService.removeUser();
    window.location.href = '/login';
  }, []);

  if (TokenService.getUserTypeGuest()) {
    return (
      <div>
        <LogOut>
          <div onClick={guestLogout}>
            <PasswordResetGuest htmlFor='logout' labelText={t('Logout')} />
          </div>
        </LogOut>
        <BorderGuest />
      </div>
    );
  }

  return (
    <>
      <AccountOptionBox>
        <AccountOption htmlFor='account_option' labelText={t('Account Options')} />
        <div onClick={() => { updateModel(); }}>
          <PasswordText htmlFor='Update_Password' labelText={t('Update Password')} />
        </div>
      </AccountOptionBox>
      <Border />
      <LogOut onClick={() => { sessionStorage.setItem('isGuestLogout', 'true'); AuthService.logoutUser(); }}>
        <PasswordReset htmlFor='logout' labelText={t('Logout')} />
      </LogOut>
    </>
  );
};

export default PasswordModal;