import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertBar, Button, Label, PasswordField, useModal } from 'scorer-ui-kit';
import { updatePassword } from 'services/apiConfig';
import TokenService from 'services/tokenService';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 407px;
  display: flex;
  flex-direction: column;
`;

const LabelTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 14px;
`;

const StatusBar = styled.div<{ color: string }>`
  width: 130px;
  height: 5px;
  margin: -6px 0 37px 0;
  border-radius: 2.5px;
  background-color: ${({ color }) => color};
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  button{
    margin-left: 10px;
  }
`;

const StatusBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: -2px;
  right: 0;
  > label {
    margin-left: auto;
  }
`;

const NewPasswordStatusText = styled(Label)`
  font-size: 12px;
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const StatusText = styled(Label)`
  font-size: 12px;
  margin-bottom: 0;
  margin-top: -13px;
  span{
    margin-bottom: 0;
    margin-left:12px;
  }
`;

const AlertBox = styled.div`
  margin-bottom: 19px;
`;

const PasswordWrapper = styled.div`
  position: relative;
  > label {
    margin-top: 4px;
  }
  button > div {
    margin-top: 4px;
  }
`;

const ButtonFormat = styled.div`
  padding-right:10px;
`;

const UpdatePassword: FC = () => {
  const [password, setPassword] = useState({ old_password: '', new_password: '', confirmPassword: '' });
  const [passwordStatus, setPasswordStatus] = useState('#d9d9d9');
  const { t } = useTranslation(['CommonDict']);
  const [goodPassword, setGoodPassword] = useState('');
  const [newPasswordStatus, setNewPasswordStatus] = useState('');
  const [alert, setAlert] = useState(false);
  const { setModalOpen } = useModal();
  const [failedStatus, setFailedStatus] = useState('');

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    setPassword(prev => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    if (password.old_password !== '') {
      if(password.new_password === ''){
        setPasswordStatus('#d9d9d9');
        setNewPasswordStatus('Enter New Password');
      } else {
        if(password.old_password === password.new_password){
          setPasswordStatus('#ff6666');
          setNewPasswordStatus('Old and New password can not be same');
          return;
        } else {
          setPasswordStatus('#d9d9d9');
          setNewPasswordStatus('');
        }
      }
    } else {
      setPasswordStatus('#d9d9d9');
      setNewPasswordStatus('');
    }
    if(password.confirmPassword === '' && password.new_password === ''){
      setPasswordStatus('#d9d9d9');
      setGoodPassword('');
    } else {
      if(password.confirmPassword === password.new_password){
        setPasswordStatus('#9dd09d');
        setGoodPassword('Matched');
      } else {
        setPasswordStatus('#ff6666');
        setGoodPassword('Not Matched');
      }
    } 
  }, [password]);

  const UpdatePassword = useCallback(async () => {
    const { old_password, new_password, confirmPassword } = password;
    if (new_password.trim() === '') {
      setFailedStatus('Please enter a valid password. Only spaces are not allowed.');
      return;
    }
    if (confirmPassword === new_password) {
      setAlert(false);
      setFailedStatus('');
      try {
        const res = await updatePassword({ old_password, password: new_password });
        if (res.status === 200) {
          setModalOpen(false);
          TokenService.removeUser();
          window.location.href = '/login';
        } else if (res.status === 500) {
          setAlert(true);
        } else if (res.status === 501) {
          setFailedStatus('Old and new password should not be same!');
        } else {
          setFailedStatus('Something wrong');
        }
      } catch (error) {
        setFailedStatus('Failed to communicate with the system');
        console.error((error as Error).message);
      }
    }
  }, [password, setModalOpen]);

  const isEnableUpdate = useCallback(() => {
    if(password.old_password === password.new_password){
      return true;
    }
    if (password.old_password === '' || password.confirmPassword === '' || password.new_password === ''){
      return true;
    }
    if (password.new_password === password.confirmPassword){
      return false;
    }
    return true;
  }, [password]);

  return (
    <Container>
      <LabelTitle htmlFor='' labelText={t('Update Password')} />
      <PasswordWrapper>
        <PasswordField
          autoFocus
          tabIndex={1}
          name='old_password'
          label={t('Old Password')}
          fieldState='default'
          onChange={onChangeHandler}
          value={password.old_password}
          autoComplete='new-password'
        />
      </PasswordWrapper>
      <PasswordWrapper>
        <PasswordField
          tabIndex={2}
          name='new_password'
          label={t('New Password')}
          fieldState='default'
          onChange={onChangeHandler}
          value={password.new_password}
          autoComplete='new-password'
        />
        <StatusContainer>
          <NewPasswordStatusText htmlFor='' labelText={t(newPasswordStatus)} />
        </StatusContainer>
      </PasswordWrapper>
      <PasswordWrapper>
        <PasswordField
          tabIndex={3}
          name='confirmPassword'
          label={t('Confirm New Password')}
          fieldState='default'
          onChange={onChangeHandler}
          value={password.confirmPassword}
          autoComplete='new-password'
        />
      </PasswordWrapper>  
      <StatusBox>
        <StatusBar color={passwordStatus} />
        <StatusText htmlFor='' labelText={t(goodPassword)} />
      </StatusBox>
      {alert && <AlertBox> <AlertBar type='error' message={t('Old Password is incorrect.')} /></AlertBox>}
      {failedStatus !== '' && <AlertBox> <AlertBar type='error' message={t(failedStatus)} /></AlertBox>}
      <ButtonBox>
        <ButtonFormat tabIndex={4}>
          <Button type='submit' id='cancel' design='secondary' onClick={() => { setModalOpen(false); }}>{t('Cancel')}</Button>
        </ButtonFormat>  
        <ButtonFormat tabIndex={isEnableUpdate() ? 0 : 5}>
          <Button id='select' type='submit' design='primary' onClick={UpdatePassword} disabled={isEnableUpdate()}>{t('Update Password')}</Button>
        </ButtonFormat>
      </ButtonBox>
    </Container>
  );
};

export default memo(UpdatePassword);