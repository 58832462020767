import { IUser } from 'interface';

const getLocalRefreshToken = () => {
  const _user = localStorage.getItem('user_login_info');
  if(_user) {
    return JSON.parse(_user)?.refresh_token;
  }
};

const getLocalAccessToken = () => {
  const _user = localStorage.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user)?.access_token;
  }
};

const updateLocalAccessToken = (token: string) => {
  const _user = localStorage.getItem('user_login_info');
  if(_user){
    const user: IUser = JSON.parse(_user);
    user.access_token = token;
    localStorage.setItem('user_login_info', JSON.stringify(user));
  }
};

const getUserSessionId = () => {
  const _user = localStorage?.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user).user?.session_id;
  }
};

const getUser = () => {
  const _user = localStorage?.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user)?.user?.username;
  }
};

const getUserID = () => {
  const _user = localStorage.getItem('user_login_info');
  if (_user) {
    return JSON.parse(_user)?.user.user_id;
  }
};


const getUserRole = () => {
  const _user = localStorage?.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user)?.user?.role;
  }
};

const getUserType = () => {
  const _user = localStorage?.getItem('user_login_info');
  if (_user) {
    return JSON.parse(_user)?.user?.role === 'admin';
  } else {
    return false;
  }
};

const getUserTypeGuest = () => {
  const _user = localStorage?.getItem('user_login_info');
  if (_user) {
    return JSON.parse(_user)?.user?.role === 'guest';
  } else {
    return false;
  }
};

const setUser = (user: IUser) => {
  localStorage.setItem('user_login_info', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user_login_info');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getUserID,
  getUserType,
  getUserTypeGuest,
  getUserRole,
  getUserSessionId
};

export default TokenService;
