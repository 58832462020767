import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { IDeviceInfo, IUploadBytes, IUploader } from 'interface';
import { DATE_FORMATT_WITH_HYPHEN, DATE_FORMAT2_WITH_SEC } from '../../constants';
import { useTranslation } from 'react-i18next';
import {
  Content,
  PageHeader,
  Switch,
  Label,
  Icon,
  Spinner,
  Button,
  ButtonWithLoading,
  useNotification,
  AlertBar,
  useModal,
} from 'scorer-ui-kit';
import {
  getCloudStatus,
  getUploadSettings,
  getUploadStatus,
  setUploadSettings,
} from 'services/apiConfig';
import { SpinnerContainer } from 'style';
import styled from 'styled-components';
import StatusBox from './StatusBox';
import UploadManagement from './UploadManagement';
import { Divider } from 'Styles';

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  max-width: 942px !important;
  line-height: 1.2;
  > div:first-child > p {
    font-weight: normal;
    font-style: normal;
  }
`;

const SwitchContainer = styled.div`
  margin-top: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0 14px;
`;

const ButtonContainer = styled.div<INotConfigured>`
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;
  position: relative;
  opacity: ${({notConfigured}) => notConfigured ? 0.5 : 1};
  ${({notConfigured}) => notConfigured && `
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  `}
`;

const CancelButton = styled(Button)`
  background: #e4edf4; 
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

const CancelMediaButton = styled(Button)`
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

const LineLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const StatusLabel = styled(Label)`
  font-size: 16px;
  color: #5a6269;
`;

const SwitchLabel = styled(LineLabel)`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  margin-left: 14px;
`;

const MainContent = styled.div`
  margin-top: 29px;
`;

const AlertBarContainer = styled.div`
  max-width: 860px !important;
  margin: 20px 0;
`;

const NoteContainer = styled.div`
  margin-top: 18px;
  width: 100%;
  max-width: 860px;
  height: 50px;
  padding: 10px 8px 10px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 14px;
  border-radius: 3px;
  color: #fff;
  background: #f69b41;
`;

const EnableButton = styled.div`
  flex-shrink: 0;
  margin-left: 97px;
  font-weight: 750;
  letter-spacing: 0.4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  gap: 0 8px;
  justify-content: flex-end;

  & > #NoButton:focus {
    outline: 2px solid #838383;
  }

  & > #YesButton:focus {
    outline: 2px solid #838383;
  }

  & > #TrueButton:focus {
    outline: 2px solid #838383;
  }
`;
const ModalMessage = styled.div`
  margin-bottom: 20px;
`;
const ModalContainer = styled.div`
  max-width: 580px;
  width: 100%;
  padding: 30px 40px;
`;

const ModalContainerCustom = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 30px 40px;
`;

const StatusContainer = styled.div`
  margin: 20px 0 43px;
`;

const ConnectionDetails1 = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 0 9px;
`;

const ConnectionDetails2 = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0 9px;
`;

const HideSwitch = styled.div<IHideSwitch>`
  z-index: ${({hideValue}) => hideValue ? -1 : 9999};
  width: 200px;
  height: 200px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 1166px;
`;

const IconAlignmnet = styled(Icon)`
  margin-top: 6px;
`;

const CustomeButtonContinue = styled(Button)`
  background: #e4edf4;
`;

const CustomeButton = styled(Button)`
  background: #e4edf4;
`;

interface INotConfigured {
  notConfigured: boolean;
}

interface IHideSwitch {
  hideValue: boolean
}

interface ITableData {
  type: string;
  enabled: boolean;
  retention: string;
}

const CloudUploadSettings: React.FC = () => {
  const [alert, setAlert] = useState<IAlert|null>(null);
  const [featureEnabled, setFeatureEnabled] = useState<boolean>(false);
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo>({
    shadow_last_update_time: new Date().toLocaleString()
  });
  const [uploaderDetails, setUploaderDetails] = useState<IUploader>();
  const [updatedUploaderDetails, setUpdatedUploaderDetails] = useState<IUploader>();
  const [uploadBytes, setUploadBytes] = useState<IUploadBytes>();
  const [cloudUploadDetails, setCloudUploadDetails] = useState<string>('');
  const [notConfigured, setNotConfigured] = useState<boolean>(false);
  const [totalPendingUpload, setTotalPendingUpload] = useState<number>(0);
  const [loadingDeviceDetails, setLoadingDeviceDetails] = useState<boolean>(true);
  const [loadingUploader, setLoadingUploader] = useState<boolean>(true);
  const [loadingUploadDetails, setLoadingUploadDetails] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { isModalOpen, createModal, setModalOpen } = useModal();
  const [uploadedDate, setUploadedDate] = useState('');
  const [removeFeatureButtonValidation, setRemoveFeatureButtonValidation] = useState(false);
  const [restoreUploadData, setRestoreUploadData] = useState<IUploader>();
  const [eraseDataValue, setEraseDataValue] = useState(false);

  const getDeviceDetails = useCallback(async () => {
    try {
      setLoadingDeviceDetails(true);
      const {
        data: {
          data: {
            device_id,
            scorer_cloud: { status, shadow_last_update_time },
          },
        },
      } = await getCloudStatus();
      setDeviceInfo({ device_id, status, shadow_last_update_time });
      setLoadingDeviceDetails(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getDeviceDetails();
    return () => {};
  }, [getDeviceDetails]);

  const getUploadData = useCallback(async () => {
    try {
      setLoadingUploader(true);
      const {
        data: {
          data
        }
      } = await getUploadSettings();
      setUploaderDetails(data);
      setRestoreUploadData(data);
      setFeatureEnabled(data.upload_enabled);
      setUpdatedUploaderDetails(data);
      setLoadingUploader(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getUploadData();
    return () => {};
  }, [getUploadData]);

  const getMaxDate = (keys:string[]) => {
    try {
      const newDates: any[] = [];
      keys.forEach((date:any) => {
        if(date === '0' || date === '') {
          return;
        }
        const splitDate = date.split(' ');
        const dateFormat = splitDate[0] + ' ' + splitDate[1];
        const d1 = new Date(dateFormat);
        newDates.push(d1);
      });
      let maxDatenew:Date | number = Math.max.apply(null, newDates);
      maxDatenew = new Date(maxDatenew);
      const maxDateformatted = format(maxDatenew, DATE_FORMAT2_WITH_SEC);
      let returnDate = '';
      keys.forEach((date:string) => {
        const splitDate = date.split(' ');
        const mydate = splitDate[0] + ' ' + splitDate[1];
        if(maxDateformatted === mydate) {
          returnDate = date;
        }
      });
      return returnDate;
    } catch (err) {
      console.log(err);
    }
    return '-';
  };

  const getUploadDetails = useCallback(async () => {
    try {
      setLoadingUploadDetails(true);
      const { data: { data } } = await getUploadStatus();
      const keys: string[] = [];
      const convertInArray = Object.entries(data?.health_status?.status_detail?.last_upload_time);
      convertInArray.forEach(([key, value]) => {
        keys.push(value as string);
        return key;
      });
      const convertedDate = getMaxDate(keys);
      setUploadedDate(convertedDate);
      setUploadBytes(data?.health_status?.status_detail?.remaining_bytes);
      setLoadingUploadDetails(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getUploadDetails();
    return () => {};
  }, [getUploadDetails]);

  const formatBytes = useCallback((bytes: number, decimals = 2) => {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }, []);

  // Calculation of tatal pending upload data of all three types
  useEffect(() => {
    const pendingUpData: number[] = [];
    if (uploadBytes) {
      /*-----------------------TEXTS------------------------------*/
      if (uploadBytes.texts === undefined) {
        pendingUpData.push(0);
      } else {
        if (uploadBytes.texts === '' && uploadBytes['priority.texts'] === '') {
          pendingUpData.push(0);
        } else {
          if (uploadBytes['priority.texts'] !== '') {
            pendingUpData.push(parseInt(uploadBytes['priority.texts']));
          } else if (uploadBytes.texts !== '') {
            pendingUpData.push(parseInt(uploadBytes.texts));
          } else {
            pendingUpData.push(
              parseInt(uploadBytes['priority.texts']) +
                parseInt(uploadBytes.texts)
            );
          }
        }
      }
      /*-----------------------IMAGES------------------------------*/
      if (uploadBytes.images === undefined) {
        pendingUpData.push(0);
      } else {
        if (
          uploadBytes.images === '' &&
          uploadBytes['priority.images'] === ''
        ) {
          pendingUpData.push(0);
        } else {
          if (uploadBytes['priority.images'] !== '') {
            pendingUpData.push(parseInt(uploadBytes['priority.images']));
          } else if (uploadBytes.images !== '') {
            pendingUpData.push(parseInt(uploadBytes.images));
          } else {
            pendingUpData.push(
              parseInt(uploadBytes['priority.images']) +
                parseInt(uploadBytes.images)
            );
          }
        }
      }
      /*-----------------------VIDEOS------------------------------*/
      if (uploadBytes.videos === undefined || uploadBytes.videos === '') {
        pendingUpData.push(0);
      } else {
        pendingUpData.push(parseInt(uploadBytes.videos));
      }
    }
    if (pendingUpData.length !== 0) {
      setTotalPendingUpload(pendingUpData.reduce((sum, num) => sum + num));
    }
    return () => {};
  }, [uploadBytes]);

  const getCloudUploadDetails = useCallback((tableData: ITableData[]) => {
    const typeArr = tableData.filter((item) => item.enabled === true);
    if (typeArr.length === 3) {
      setCloudUploadDetails('Logs, Images & Videos');
    } else if (typeArr.length === 1) {
      setCloudUploadDetails('Logs Only');
    } else {
      setCloudUploadDetails(`${typeArr[0].type} & ${typeArr[1].type}`);
    }
    setUpdatedUploaderDetails((prevVal)=>{
      if (prevVal !== undefined){
        return {
          ...prevVal,
          upload_enabled: featureEnabled,
          texts_data_retention_days: tableData[0].retention,
          texts_enabled: tableData[0].enabled,
          images_data_retention_days: tableData[1].retention,
          images_enabled: tableData[1].enabled,
          videos_data_retention_days: tableData[2].retention,
          videos_enabled: tableData[2].enabled
        };
      }
    });
  }, [featureEnabled]);

  
  const handleCancel = useCallback(async () => {
    setModalOpen(false);
    try {
      await getUploadData();
      sendNotification({
        type: 'success',
        message: t('The changes were discarded and not saved.')
      });
    } catch (error) {
      sendNotification({
        type: 'error',
        message: t('Failed to reset SCORER Cloud upload settings.')
      });
    }
  }, [t, setModalOpen, getUploadData, sendNotification]);
  
  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();
      if (e.key === 'ArrowLeft' && e.target.id === 'YesButton') {
        document.getElementById('NoButton')?.focus();
      } if (e.key === 'ArrowLeft'&& e.target.id === 'TrueButton') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'ArrowRight' && e.target.id === 'NoButton') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'ArrowRight' && e.target.id === 'YesButton') {
        document.getElementById('TrueButton')?.focus();
      } 
      else if (e.key === 'Escape') {
        document.getElementById('NoButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'NoButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        if (document.activeElement?.id === 'YesButton') {
          document.getElementById('TrueButton')?.focus();
          return;
        }
        document.getElementById('NoButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('NoButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
    return () => {};
  }, [isModalOpen, arrowEvent]);
  
  // Confirm Modal
  const getConfirmationModal = useCallback(() => {
    return (
      <ModalContainer>
        <ModalMessage>{t('Are you sure you want to cancel the changes?')}</ModalMessage>
        <ModalButtonContainer>
          <CancelButton
            id='NoButton'
            design='secondary'
            onClick={() => setModalOpen(false)}
          >
            {t('No')}
          </CancelButton>
          <Button
            id='YesButton'
            design='danger'
            onClick={handleCancel}
          >
            {t('Yes')}
          </Button>
        </ModalButtonContainer>
      </ModalContainer>
    );
  }, [t, setModalOpen, handleCancel]);

  const onCancel = useCallback(() => {
    const confirmModal: ReactElement = getConfirmationModal();
    createModal({
      isCloseEnable: false,
      width: '480px',
      padding: false,
      customComponent: confirmModal,
    });
  }, [createModal, getConfirmationModal]);

  const onSave = useCallback(async (toggleString: string, eraseData:string, toggleValue:boolean) => {
    let jsonData;
    if(eraseData === 'EraseData'){
      jsonData = Object.assign({}, restoreUploadData);
      jsonData.upload_enabled = toggleValue;
      setEraseDataValue(true);
    }else {
      jsonData = Object.assign({}, updatedUploaderDetails);
      jsonData.upload_enabled = toggleValue;
    }
    setLoadingSave(true);
    try {
      setRestoreUploadData(jsonData);      
      const res = await setUploadSettings(jsonData);
      setLoadingSave(false);
      if(res.data.status_code === '10000'){
        setUploaderDetails(jsonData);
        if(toggleString === 'InToggle' && !toggleValue){
          sendNotificationRef.current({
            type: 'success',
            message: tRef.current('SCORER Cloud Uploader disabled successfully.')
          });
          return;
        }else if(toggleString === 'InToggle' && toggleValue) {
          sendNotificationRef.current({
            type: 'success',
            message: tRef.current('SCORER Cloud Uploader enabled successfully.')
          });
          return;
        }
        sendNotificationRef.current({
          type: 'success',
          message: tRef.current('SCORER Cloud Uploader settings saved successfully.')
        });
      } else {
        sendNotificationRef.current({
          type: 'error',
          message: tRef.current('Failed To Save SCORER Cloud Uploader settings.')
        });
      }
    } catch (error) {
      setLoadingSave(false);
      sendNotificationRef.current({
        type: 'error',
        message: tRef.current('Failed To Save SCORER Cloud Uploader settings.')
      });
    }
  }, [restoreUploadData, updatedUploaderDetails]);

  const handleSave = useCallback(()=>{
    setFeatureEnabled(prev=>!prev);
    onSave('InToggle', 'EraseData', !featureEnabled);
    setModalOpen(false);
  }, [featureEnabled, onSave, setModalOpen]);

  const handleModalSave = useCallback(() =>{
    setModalOpen(false);
    setFeatureEnabled(prev=> !prev);
    onSave('NotToggle', 'NotErase' , !featureEnabled);
  }, [featureEnabled, onSave, setModalOpen]);

  const handlModalCancel = useCallback(() =>{
    setModalOpen(false);
  }, [setModalOpen]);

  // Confirm Modal
  const getConfirmationModalBox = useCallback(() => {
    return (
      <ModalContainerCustom>
        <ModalMessage>{t('You have unsaved changes. Do you want to save?')}</ModalMessage>
        <ModalButtonContainer>
          <CustomeButton
            id='NoButton'
            design='secondary'
            onClick={() => handlModalCancel()}
          >
            {t('Cancel')}
          </CustomeButton>
          <CancelMediaButton
            id='YesButton'
            design='primary'
            onClick={() => handleModalSave()}
          >
            {t(`Save and ${featureEnabled ? 'Disable' : 'Enable'}`)}
          </CancelMediaButton>          
          <CustomeButtonContinue
            id='TrueButton'
            design='secondary'
            onClick={() => handleSave()}
          >
            {t(`Discard and ${featureEnabled ? 'Disable' : 'Enable'}`)}
          </CustomeButtonContinue>
        </ModalButtonContainer>
      </ModalContainerCustom>
    );
  }, [t, featureEnabled, handlModalCancel, handleModalSave, handleSave]);

  const confirmationModal = useCallback(() => {
    const confirmationModalBox: ReactElement = getConfirmationModalBox();
    createModal({
      width: '600px',
      padding: false,
      customComponent: confirmationModalBox,
      isCloseEnable: false
    });
  }, [createModal, getConfirmationModalBox]);

  const onFeatureToggle = useCallback((e) => {
    if(isEqual(uploaderDetails, updatedUploaderDetails)){
      setFeatureEnabled(e);
      onSave('InToggle', 'NotErase', e);
      setRemoveFeatureButtonValidation(false);
    }else{
      confirmationModal();
    }
  }, [confirmationModal, onSave, updatedUploaderDetails, uploaderDetails]);

  useEffect(()=>{
    if(deviceInfo.status !== undefined && deviceInfo.status !== 'Connected'){
      if (featureEnabled) {
        setAlert({key: new Date(), message: t('Please register the device using SCORER Starter to establish the secure connection to SCORER Cloud'), type: 'warning'});
      } else {
        setAlert(null);
      }
      setNotConfigured(true);
    } else {
      setNotConfigured(false);
      setAlert(null);
    }
    return () => {};
  },[deviceInfo, featureEnabled, t]);

  const hideSwitch = () =>{
    confirmationModal();
  };

  return (
    <>
      {!loadingUploader && !loadingUploadDetails ? (
        <Content>
          <Header>
            <PageHeader
              title={t('SCORER Cloud Upload')}
              areaTitle={t('Settings')}
              icon='ViewSettings'
              introductionText={t(
                'Enable Synchronization of your data to SCORER Cloud.'
              )}
              updateDocTitle={false}
            />
            <HideSwitch onClick={hideSwitch} hideValue={isEqual(uploaderDetails, updatedUploaderDetails)} />
            <SwitchContainer>
              <SwitchLabel>{t('Feature Enabled')}</SwitchLabel>
              <Switch
                checked={featureEnabled}
                onChangeCallback={onFeatureToggle}
                state={loadingSave ? 'disabled' : 'default'}
              />
            </SwitchContainer>
          </Header>
          <MainContent>
            <StatusLabel htmlFor='connStatus' labelText={t('Connection Status')} />
            <StatusContainer>
              <ConnectionDetails1>
                <StatusBox
                  title={t('Status')}
                  details={deviceInfo?.status ? t(deviceInfo.status) : ''}
                  statusColor={
                    (notConfigured || loadingDeviceDetails) ? '#d9d9d9' : '#87d58f'
                  }
                  isLoader={loadingDeviceDetails}
                />
                <StatusBox
                  title={t('Device Name')}
                  details={deviceInfo?.device_id}
                  statusColor='transparent'
                  isLoader={loadingDeviceDetails}
                />
              </ConnectionDetails1>
              <ConnectionDetails2>
                <StatusBox
                  title={t('Contents to Upload')}
                  details={!featureEnabled ? t('Disabled') : t(cloudUploadDetails)}
                  notConfigured={notConfigured}
                  statusColor='transparent'
                />
                <StatusBox
                  title={t('Last Upload Time')}
                  details={notConfigured ? '-' : uploadedDate === '-' ? '-' : format(new Date(uploadedDate.slice(0, -3)), DATE_FORMATT_WITH_HYPHEN) + ' JST'}
                  notConfigured={notConfigured}
                  statusColor='transparent'
                  isLoader={loadingDeviceDetails}
                />
                <StatusBox
                  title={t('Pending Uploads')}
                  details={
                    totalPendingUpload === 0
                      ? '-'
                      : formatBytes(totalPendingUpload)
                  }
                  notConfigured={notConfigured}
                  statusColor='transparent'
                />
              </ConnectionDetails2>
            </StatusContainer>
            {
              alert && 
                <AlertBarContainer>
                  <AlertBar message={alert?.message} type={alert?.type} />
                </AlertBarContainer>
            }
            <Divider />
            {!featureEnabled && !notConfigured && (
              <NoteContainer>
                <IconAlignmnet size={20} icon='BigWarning' color='inverse' />
                <div>
                  {t('The settings below can be changed but you must enabled this feature for uploads to begin.')}
                </div>
                <EnableButton onClick={() => onFeatureToggle(true)}>
                  {t('ENABLE NOW')}
                </EnableButton>
              </NoteContainer>
            )}
            <UploadManagement
              isSavingState={loadingSave}
              inputData={uploaderDetails}
              featureEnabled={featureEnabled}
              notConfigured={notConfigured}
              getCloudUploadDetails={getCloudUploadDetails}
              setRemoveFeatureButtonValidation={setRemoveFeatureButtonValidation}
              eraseDataValue={eraseDataValue}
            />
            <ButtonContainer notConfigured={notConfigured}>
              <ButtonWithLoading
                loading={loadingSave}
                design='primary'
                disabled={removeFeatureButtonValidation ? isEqual(uploaderDetails, updatedUploaderDetails) : true}
                onClick={()=>onSave('NotToggle', 'NotErase' ,featureEnabled)}
              >
                {t('Save Changes')}
              </ButtonWithLoading>
              <CancelButton
                design='secondary'
                disabled={isEqual(uploaderDetails, updatedUploaderDetails)}
                onClick={onCancel}
              >
                {t('Cancel')}
              </CancelButton>
            </ButtonContainer>
          </MainContent>
        </Content>
      ) : (
        <SpinnerContainer>
          <Spinner size='large' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading')+'...'} />
        </SpinnerContainer>
      )}
    </>
  );
};

export default CloudUploadSettings;