import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'scorer-ui-kit';
import TokenService from 'services/tokenService';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  right: 99px;
  top: 20px;
  margin-top: 0px;
  z-index: 50;
`;

const LabelFormatter = styled(Label)`
  font-weight:600;
`;

const Role = () => {
  const { t } = useTranslation(['CommonDict']);
    
  return (
    <Container>
      <LabelFormatter htmlFor='' labelText={t((TokenService.getUserRole() === 'admin') ? 'Administrator' : TokenService.getUserRole().charAt(0).toUpperCase()+ TokenService.getUserRole().slice(1))} />
    </Container>
  );
};

export default Role;
