import React, { FC, useCallback, useState } from 'react';
import { EDGE_API_BASE_URL } from '../constants';
import { LineSetContext, LineUI, usePoll } from 'scorer-ui-kit';
import axios from 'axios';
import { IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import { IReducerActions } from 'scorer-ui-kit/dist/LineUI/LineReducer';

interface IImageResponse {
    data: ArrayBuffer,
    status: number,
}

interface IMediaModal {
    image: string;
    streamName: string;
    state: IPointSet[] | [];
    dispatch: (value: IReducerActions) => void;
    options: LineUIOptions;
    bufferTime: number;
}

const MediaModal: FC<IMediaModal> = ({state, dispatch, image, options, streamName, bufferTime=3000 }) => {

  const [imageURL, setImageURL] = useState(image);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}/stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        if (res.data.byteLength > 5) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          setImageURL(imgBase64);
        } else {
          setImageURL('');
        }
      } 
    } catch (err) {
      console.error(err);
    }
  }, [streamName]);

  usePoll(async () => {
    await getCameraImage();
  }, bufferTime);


  return (
    <>
      <LineSetContext.Provider value={{ state, dispatch }}>
        <LineUI 
          options={options} 
          src={imageURL} 
          hasClickSensingBorder={false} 
        />
      </LineSetContext.Provider>
    </>
  );
};  

export default MediaModal;