// store.ts
import { legacy_createStore as createStore} from 'redux';

interface State {
  pinnedGraphData: any[];
}

interface State {
  pinnedIdOnClick: any[];
}

interface Action {
  type: string;
  payload: any;
}

const initialState: State = {
  pinnedGraphData: [],
  pinnedIdOnClick: [],
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
  case 'SET_PINNED_GRAPH_DATA':
    return {
      ...state,
      pinnedGraphData: action.payload,
    };
  case 'SET_PINNED_ID_ONCLICK':
    return {
      ...state,
      pinnedIdOnClick: action.payload,
    };
  default:
    return state;
  }  
};

const store = createStore(reducer);

export default store;