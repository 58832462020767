import * as React from "react";
const SvgFilterSorting = (props: ISvgIcons) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox={"0 0 24 24"}
      role="img"
      {...props}
    >
      <path
        d="M7 12h10m-7 5h4M5 7h14"
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgFilterSorting;
