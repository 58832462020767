import * as React from "react";
const SvgActionPause = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      role="img"
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <rect x={5.25} y={3.75} width={4.5} height={16.5} rx={1.5} />
        <rect x={14.25} y={3.75} width={4.5} height={16.5} rx={1.5} />
      </g>
    </svg>
  );
};
export default SvgActionPause;
